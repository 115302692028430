import React from 'react'
import Artwork from './artwork'

const ServicesItem = ({ serviceIcon, serviceName, servicePrice, serviceItems }) => (
  <div className="max-w-sm service-item">
    <header>
      <Artwork type={serviceIcon} />
    </header>
    <div className="py-4">
      <div className="flex justify-between mb-2 text-xl font-bold uppercase service-title">
        {serviceName}
        <figure><sup>$</sup>{parseFloat(servicePrice).toFixed(2)}</figure>
      </div>
      {serviceItems.length > 1 ? <ul className="list-disc list-inside md:m-6">
        {serviceItems.map(x => <li key={x} className="text-sm">{x}</li>)}
      </ul> : <p>{serviceItems[0]}</p>}
    </div>
  </div>
)

export default ServicesItem
