import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const BrandLogos = () => {
  const data = useStaticQuery(graphql`
    query {
      brands: allImageSharp {
        nodes {
          fixed {
            base64
            tracedSVG
            aspectRatio
            srcWebp
            srcSetWebp
            originalName
            src
          }
          id
        }
      }
    }
  `)

  const normalizeBrandName = s => s.match(/\/[0-9a-zA-Z\-.]+-logo-/)[0].replace(/\//, '').replace(/logo/, '').replace(/-/g, ' ').trim()
  const brands = data.brands.nodes.map(x => ({ ...x, name: normalizeBrandName(x.fixed.src) }))

  return (
    <div className="flex flex-wrap justify-around">
    {brands.map(x =>
      <div key={x.id} className="flex items-center justify-center w-56 m-6">
        <img src={x.fixed.src} key={x.id} className="w-full h-auto" alt={x.name} />
      </div>
    )}
    </div>
  )
}

export default BrandLogos
