import React from 'react'

const Details = ({
  title,
  name,
  phoneNumber,
  email,
  abn
}) => (
    <div className="flex flex-col mx-auto my-6 lg:justify-center">
      <h3 className="font-bold">{title}</h3>
      <p className="md:text-2xl">{name}</p>
      <div className="mb-6 md:text-xl">
        PH: <a href={`tel:${phoneNumber.replace(/\s/g, '')}`}>{phoneNumber}</a>
      </div>
      <div className="mb-6 md:text-xl">
        EM: <a href={`mailto:${email}`}>{email}</a>
      </div>
      <div className="mb-6 md:text-xl">
        ABN: {abn}
      </div>
    </div>
  )

export default Details
