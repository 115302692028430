import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src549097362/src/abr-site/src/components/layout.js";
import { Link } from 'gatsby';
import SEO from '../components/seo.js';
import ServicesList from '../components/services-list.js';
import ContactDetails from '../components/contact-details.js';
import ContactForm from '../components/contact-form.js';
import BrandLogos from '../components/brand-logos.js';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Arncliffe Bicycle Repairs" mdxType="SEO" />
    <section className="pane max-w-screen-2xl">
      <div className="w-full flex justify-center flex-col text-center mb-6">
        <h4>{`New workshop location`}</h4>
  <fieldset id="open-info">
    <address className="sm:text-2xl md:mb-3 md:text-3xl font-bold">
    190 Kingsgrove Road, Kingsgrove
    </address>
    <div className="flex flex-row justify-center sm:text-2xl">
      <span><time>7pm</time> - <time>10pm</time></span>
      <span className="ml-3">(Tuesday - Friday)</span>
    </div>
  </fieldset>
      </div>
      <h1>{`Services`}</h1>
      <ServicesList mdxType="ServicesList" />
      <Link to="/price-list" className="text-center" mdxType="Link">
        <h3>{`Complete Price List`}</h3>
      </Link>
    </section>
    <section className="pane max-w-screen-xl md:px-12">
      <h1>{`About Us`}</h1>
      <article className="max-w-screen-lg mx-auto">
        <p>{`We know how hard it is to drop off your bike to a shop during work hours and no one wants to drop off their bike over the weekend, that's when you're supposed to be riding. At Arncliffe Bicycle Repairs we offer a pick up and delivery service or if you prefer you can bring it to our Arncliffe drop off location.`}</p>
        <p>{`MTB, Road Bike, BMX, we love them all and we can handle whatever you throw at us. We can even service your eBike. Cycling is our passion and we want to share it with you. Bicycle restoration, repairs and servicing, custom builds, detailed cleaning or even just fixing a flat tyre. We are here to help and get you back in the saddle and rollin' in no time.`}</p>
        <p>{`Our mechanic Solomon Barbar has attended BMTI (Bicycle Mechanic Training Institute) and completed Certificate II in Bicycle Mechanical Technology (AUR20316) and Certificate III in Bicycle Workshop Operations (AUR30216)`}</p>
        <p>{`Our pick up and drop off times are very flexible, send us a message or give us a call to discuss how we can help.`}</p>
      </article>
    </section>
    <section className="pane max-w-screen-lg px-12">
      <BrandLogos mdxType="BrandLogos" />
    </section>
    <section className="pane max-w-screen-md">
      <h1>{`Contact Us`}</h1>
      <div className="flex flex-col justify-center max-w-screen-md mx-auto my-12 contact-us md:flex-row">
  <ContactDetails title="Arncliffe Bicycle Repairs" name="Solomon Barbar" phoneNumber="0451 437 008" email="arncliffebicyclerepairs@gmail.com" abn="51 784 004 212" mdxType="ContactDetails" />
      </div>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      