import React from 'react'
import ServicesItem from './services-item'
import { graphql, useStaticQuery } from 'gatsby'

const ServicesList = () => {
  const data = useStaticQuery(graphql`
    query {
      yaml: allServicesYaml (sort: { fields: display_order order: ASC }) {
        nodes {
          id
          services {
            service_items
            service_name
            service_price
            service_icon
          }
        }
      }
    }
  `)

  const tiers = data.yaml.nodes

  return tiers.map(tier => <ul className="flex flex-wrap justify-center services-list" key={tier.id}>
    {tier.services.map(({ service_icon, service_name, service_price, service_items }) =>
      <li key={service_name} className="flex justify-center w-full max-w-screen-sm md:w-auto service">
        <ServicesItem
          serviceIcon={service_icon}
          serviceName={service_name}
          servicePrice={service_price}
          serviceItems={service_items} />
      </li>
    )}
  </ul>)
}

export default ServicesList
